// Theme variables.
$--accent: --accent;
$--accent-background: --accent-background;
$--anchor: --anchor;
$--button: --button;
$--cta: --cta;
$--cta-hover: --cta-hover;
$--divider-color: --divider-color;
$--error: --error;
$--font-color: --font-color;
$--results: --results;

// Default theme.
$theme-map-default: (
  $--accent: #f99b1c,
  $--accent-background: #f6f6f6,
  $--anchor: #1e1e1e,
  $--button: #fc7b1c,
  $--cta: #fc7b1c,
  $--cta-hover: #1ca0fc,
  $--divider-color: rgba(255, 255, 255, .2),
  $--error: #ff4600,
  $--font-color: rgb(90, 90, 90),
  $--results: #00a4df,
);
