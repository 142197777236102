@import "scss/themes.scss";

[data-namespace="viking-002"] {
  ::placeholder {
    opacity: .8;
  }
  a, button {
      transition: all .25s ease;
      color: var($--anchor);
  }
  a, a:hover, a:focus, a:active {
    text-decoration: none;
  }
  a:hover, a:focus, a:active {
   color: var($--cta);
 }
  .container {
    width: 100%;
    max-width: 1200px;
    display: block;
    margin: 0 auto;
    padding: 20px 20px 10px;
  }
  .button-trigger {
    display: inline-block;
    vertical-align: middle;
    padding: 0 40px 4px;
    border: 1px solid transparent;
    text-align: center;
    background-color: var($--button);
    font-family: "Raleway", Arial, sans-serif;
    font-size: 15px;
    height: 40px;
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 36px;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: all .25s ease;
    &:hover, &:active, &:focus {
      background-color: var($--cta-hover);
      color: #fff;
    }
    &.mini {
      height: 24px;
      line-height: 24px;
      padding: 0 10px;
      font-size: 12px;
    }
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: "Raleway", Arial, sans-serif;
    font-weight: 300;
    letter-spacing: .5px;
  }
  h1 {
    font-size: 50px;
    line-height: 56px;
    @media(max-width: 480px){
      font-size: 36px;
      line-height: 40px;
    }
  }
  h2 {
    font-size: 36px;
    margin-bottom: 10px;
    @media(max-width: 480px){
      font-size: 24px;
      line-height: 30px;
    }
  }
  p {
    font-weight: 300;
  }
  .isLoadingSiteData {
    opacity: 0 !important;
  }
  .page-wrapper {
    padding-top: 70px;
  }
  .highlight {
    color: var($--cta);
  }
  .page-container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    @media(max-width: 480px){
      display: block;
    }
    .main-wrapper {
      flex: 5;
      padding-right: 20px;
      @media(max-width:480px){
        padding-right: 0;
      }
    }
    .rail-container {
      flex: 2;
      background: rgba(180, 180, 180, .1);
      border-radius: 3px;
    }
  }
}
